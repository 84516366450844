import { useRef } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";


//layouts
import Header from "./../layouts/Header";
import Footer from "./../layouts/Footer";
import ScrollToTop from "./../layouts/ScrollToTop";


//Pages
import Home from "./Home";
import AboutUs from "./AboutUs";
import Team from "./Team";
import Services from "./Services";
import ServicesDetails from "./ServicesDetails";
import BlogDetail from "./BlogDetail";
import ContactUs from "./ContactUs";
import ErrorPage from "./ErrorPage";

function Index() {
  var baseName = "/";
  const scrollTopBtn = useRef(null);

  window.onscroll = () => {
    window.scrollY > 650
      ? scrollTopBtn.current.setAttribute("style", "display:block")
      : scrollTopBtn.current.setAttribute("style", "display:none");
  };
  return (
    <BrowserRouter basename={baseName}>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/"  exact element={<Home />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/pillars/:id" exact element={<BlogDetail />} />
          <Route path="/team" exact element={<Team />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/services-details/:id" exact element={<ServicesDetails />} />
          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="*" element={<ErrorPage />} /> 

        </Route>
        {/* <Route path="/"  element={<ComingSoon />} /> */}
        {/* Error page */}
        <Route path="*" element={<ErrorPage />} /> 
      </Routes>
  
      <ScrollToTop />
      <button
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        ref={scrollTopBtn}
        className="scroltop icon-up"
        type="button"
      >
        <i className="fas fa-arrow-up"></i>
      </button>
    </BrowserRouter>
  );
}

function MainLayout() {
  return (
    <div className="page-wraper">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}


export default Index;
